<template>
  <div id="app" :class="{ screentest: userAgent.screentest}">
    <section :class="{ 'main-wrapp': true}">
       <header>
        <header-top />
       </header>
       <main>
        <router-view />
        <rotate-lock v-if="userAgent.orientation === 'landscape' && userAgent.device.isPhone"></rotate-lock>      
        </main>
       <footer>
          <brand-footer />
        </footer>
    </section>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import HeaderTop from "@/Components/Layouts/Header/HeaderTop";
import BrandFooter from "@/Components/Layouts/Brand/BrandFooter";
import rotateLock from "@/Components/common-blocks/rotate-lock/rotate-lock";
export default {
  name: "App",
  components: {
    "rotate-lock": rotateLock,
    "header-top": HeaderTop,
    BrandFooter,
    basePath: process.env.BASE_URL,
  },
  beforeMount() {
    this.$store.commit(
      "UPDATE_SCREENTEST",
      this.$route.query.screentest || process.env.VUE_APP_SCREENTEST
    );
  },
  data() {
    return {
      onBottom: false,
      pageBottomPosition: null      
    };
  },
  computed: {
    ...mapState(["userAgent"]),
  },
};
</script>

<style lang="scss" src="./Styles/Main.scss"></style>