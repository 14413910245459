<template src="./Home.html"></template>
<script>
import { mapState } from "vuex";
import HeroSection from "@/Components/Blocks/HeroSection/HeroSection";
export default {
  name: "HomePage",
  components: {
    HeroSection,
  },
  data() {
    return {
      isActiveOverlay: false,
      deadInteractivity: true,
      sections: {},
      sectionScene: [],
      options: {
        container: "body",
        easing: "ease-in",
        offset: 0,
      },
      publicPath: process.env.BASE_URL,
      microbeAnimationScene_1: {},
      popupTimer: null,
      backplateTimer: null,
      resizeTimer: null,
      initTileProps: [],
      postFontSize: 1,
    };
  },
  computed: {
    ...mapState([
      "userAgent",
      "urls",
      "animationsData",
      "controllerExist",
      "mainMenu",
      "activeSection",
    ]),
    isIE() {
      return this.userAgent.browser.name === "ie";
    },
    routes() {
      return this.$router.options.routes;
    },
  },
  mounted() {
    let checkScreenTest = document
      .getElementById("app")
      .classList.contains("screentest");
  },
};
</script>

<style lang="scss" src="./Home.scss" scoped></style>