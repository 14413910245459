<template>
  <div class="brandFooter">
    <div class="contentWrap">
      <div class="brandFooter__upper">
        <div class="logo-code-row">
          <div class="code-col">
            <p>Copyright &copy; 2024 Merck & Co., Inc., Rahway, NJ, USA and its affiliates. All rights reserved.</p>
            <p>US-OOC-02591 09/24</p>
          </div>
          <div class="logo-col">
            <img src="@/Assets/Img/logos/merck-logo.svg" alt="Merck logo" />
          </div>
        </div>
        <div class="brandFooter__disclaimer">
          This <i>Cancer Therapy Advisor ReelView </i> is produced as a basic reminder of important information for healthcare professionals. Readers are advised to consult manufacturers and specialists if questions arise about specific products, treatments, or diseases. The publisher and editors do not assume liability for any errors or omissions. <i>Cancer Therapy Advisor</i> and <i>ReelView</i> are registered trademarks of Haymarket Media, Inc.  
        </div>
      </div>
    </div>
    <div class="brandFooter__lower">
      <div class="contentWrap">
        <div class="brandFooter__lowerContent">
          <div class="brandFooter__copyright">
            &copy; 2024 Haymarket Media, Inc. <nobr>All rights reserved.</nobr>
          </div>
          <div class="brandFooter__links">
            <a :href="urls.PP.link" target="_blank">{{ urls.PP.text }}</a>
            <a :href="urls.TC.link" target="_blank">{{ urls.TC.text }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BrandFooter",
  computed: {
    ...mapState(["urls"]),
  },
};
</script>

<style lang="scss" src="./BrandFooter.scss"></style>