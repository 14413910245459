<template>
  <div class="heroSectonWrap">
    <div class="heroSectonWrap__topHightlight">
      <div class="contentWrap">
        <h3>Exploring Risk of Recurrence for Certain Stages of Completely <span>Resected Melanoma:</span></h3>
      </div>
    </div>
    <div class="contentWrap">
      <div class="heroSection">
        <div class="heroSection__right">
          <!-- <div class="video-placeholder">
            <img class="brightcove-img-placeholder" src="@/Assets/Img/video-placeholder-thumb.jpg" alt="Resected Melanoma" />
          </div> -->
          <div class="brightcove-video">
            <span class="fullscreen" @click="toggleFullScreen"></span>
            <img class="brightcove-img-placeholder" src="@/Assets/Img/video-placeholder-thumb.jpg" alt="Rare Disease Advisor" />
            <div class="brightcove-video-holder" ref="videoplayer" id="videoplayer"></div>
          </div>
        </div>
        <div class="heroSection__left">
          <div class="heroSection__content">
            <h4>Insights From Scientific Leaders <span class="noWrap--desktop">on 2 Retrospective</span>  Studies and a Case <span class="noWrap--desktop">of a</span> Hypothetical Patient with
             <span class="noWrap--desktop">Stage IIC</span> Melanoma</h4>
            <p class="textDetail">In this video, experts Dr. Sondak and Dr. Najjar highlight a hypothetical patient with
              stage IIC melanoma and discuss the role of the multidisciplinary team and its approach to treatment of
              patients with earlier stage cancer.</p>
            <p><strong>Learn more about an appropriate treatment option for this hypothetical patient.</strong></p>
            <div class="btnWrap"><a href="https://www.keytrudahcp.com/efficacy/melanoma-adjuvant-therapy/"
                target="_blank" class="btn">See Clinical Data</a></div>
          </div>
        </div>
      </div>

      <div class="video-placeholder-fullscreen">
        <span class="cross">&times;</span>
        <div class="video-placeholder-title">
          <h3>Press <span>ESC</span> to exit full screen</h3>
        </div>
        <div class="popupoverlay"></div>
        <span class="fpo">
          <span>F</span>
          <span>P</span>
          <span>O</span>
        </span>
        <img src="@/Assets/Img/video-placeholder-thumb-screen.jpg" alt="Resected Melanoma" class="desktopThumb" />
        <img src="@/Assets/Img/video-placeholder-thumb-mobile.jpg" alt="Resected Melanoma" class="mobileThumb" />
      </div>
      <div class="video-placeholder-mobile">
        <img src="@/Assets/Img/vertical-screen.png" alt="vertical screen" class="verticalImg"/>
        <img src="@/Assets/Img/horizontal-screen.png" alt="horizontal screen" class="horizontalImg"/>
      </div>
    </div>
  </div>
</template>

<script>
import PlayerLoader from '@brightcove/player-loader';
import ClickAnalytics from "@/mixins/ClickAnalytics";

export default {
  name: "HeroSection",
  computed: {
    routes() {
      return this.$router.options.routes;
    },
  },
  props: {
    HeroSection: Object,
  },
  data() {
    return {
      playerId: "6362722314112",
      videoplayer: null,
      windowWidth: window.innerWidth
    };
  },
  mixins: [ClickAnalytics],
  methods: {
    async initBrightcovePlayer() {
      const accountId = '77368774001';
      const playerId = 'GDpYGdaE3E';
      const videoId = this.playerId;
      this.videoplayer = await PlayerLoader({
        accountId: accountId,
        playerId: playerId,
        videoId: videoId,
        refNode: "#videoplayer",
        autoplay: true,
        muted: true,
      }).then(function (success) {
        var myPlayer = success.ref;
        myPlayer.on('loadedmetadata', function () {
          myPlayer.muted(true);
          myPlayer.play();
        });
      })
        .catch(function (error) {
        });
    },
    toggleFullScreen() {
      var video = document.getElementsByTagName('video')[0];
      if (video.webkitEnterFullscreen) {
        video.webkitEnterFullscreen();
      } else if (video.requestFullscreen) {
        video.requestFullscreen();
      } else {
        console.log('Fullscreen API is not supported on this browser.');
      }
    },
  },
  mounted() {
    this.initBrightcovePlayer();
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    };
    var time = 0;
    if (window.preloaderFinish === undefined) {
      time = 1.5;
    }
  }
};
</script>

<style lang="scss" src="./HeroSection.scss" scoped></style>